import {
  type ChangeEvent,
  cloneElement,
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  memo,
  type MemoExoticComponent,
  type ReactElement,
  type ReactNode,
  useCallback,
} from 'react';
import { useTheme } from '@emotion/react';
import cn from 'classnames';
import { noop } from 'lodash';

import { useFocus } from '@eversity/ui/utils';

import { ICON_SIZES } from '../../general/icon/constants';
import { Typography } from '../../general/typography/Typography';
import {
  SWITCH_INPUT_SIDES,
  SWITCH_SIZE_TYPOGRAPHY_MAPPING,
  SWITCH_SIZES,
} from './constants';
import * as styles from './Switch.styles';

const ICON_SIZE_MAPPING = {
  [SWITCH_SIZES.SMALL]: 12,
  [SWITCH_SIZES.MEDIUM]: ICON_SIZES.MEDIUM,
};

export type SwitchProps = Omit<
  HTMLProps<HTMLInputElement>,
  'onChange' | 'size' | 'ref'
> & {
  /** Switch description text. */
  description?: ReactNode;
  /** Input size. */
  size?: SWITCH_SIZES;
  /** Side displaying the switch relative to the description. */
  side?: SWITCH_INPUT_SIDES;
  /** Icon to display into the switch. */
  icon?: ReactElement;
  /** On change handler. (checked, event) => null. */
  onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
};

export const SwitchBase = forwardRef(
  (
    {
      description = null,
      size = SWITCH_SIZES.MEDIUM,
      icon = null,
      onChange = noop,
      className = null,
      side = SWITCH_INPUT_SIDES.LEFT,
      ...props
    }: SwitchProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const theme = useTheme();
    const { isFocused, ...focusProps } = useFocus(props);

    // Extract the value from the event so we don't have to override onChange everywhere.
    // Pass event as 2nd parameter if needed in some obscure case.
    const onChangeProxy = useCallback(
      (event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.checked, event),
      [onChange],
    );

    const isChecked = !!props.checked;

    return (
      <styles.SwitchContainer
        className={cn(className, size, side, {
          focused: isFocused,
          disabled: props.disabled,
          checked: isChecked,
        })}
      >
        <input
          {...props}
          {...focusProps}
          ref={ref}
          type="checkbox"
          onChange={onChangeProxy}
          css={styles.input}
        />

        <styles.ControlContainer>
          <styles.InnerControl>
            {icon ? (
              <styles.Control className={cn({ checked: isChecked })}>
                {cloneElement(icon, {
                  size: ICON_SIZE_MAPPING[size],
                  fill: isChecked
                    ? theme.colors.primary[500]
                    : theme.colors.gray[700],
                })}
              </styles.Control>
            ) : (
              <styles.Control />
            )}
            <styles.Control />
          </styles.InnerControl>
        </styles.ControlContainer>

        {!!description && (
          <Typography variant={SWITCH_SIZE_TYPOGRAPHY_MAPPING[size]}>
            {description}
          </Typography>
        )}
      </styles.SwitchContainer>
    );
  },
);

SwitchBase.displayName = 'Switch';

export const Switch: MemoExoticComponent<typeof SwitchBase> & {
  SIZES?: typeof SWITCH_SIZES;
  SIDES?: typeof SWITCH_INPUT_SIDES;
} = memo(SwitchBase);

Switch.SIZES = SWITCH_SIZES;
Switch.SIDES = SWITCH_INPUT_SIDES;
