import { css } from '@emotion/react';

export const mainContainer = css`
  position: relative;
  display: flex;

  &:not(.isSeen) {
    animation:
      color-up 2s,
      pulse 2s 1.5s infinite;

    @keyframes color-up {
      from {
        filter: invert(55%);
      }
      to {
        filter: invert(0%);
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  &.isDisabled {
    filter: invert(35%);
  }
`;

export const iconContainer = css`
  position: absolute;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  z-index: 10;
`;

const sparkleStyles = css`
  position: absolute;
  display: flex;

  &.isDefault {
    display: none;
  }

  z-index: 90;
`;

export const firstSparkle = css`
  ${sparkleStyles};
  top: 11%;
  left: 53%;
`;

export const secondSparkle = css`
  ${sparkleStyles};
  bottom: 12%;
  left: 31%;
`;
