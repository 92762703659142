import { cloneElement, memo, type MemoExoticComponent, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Plus } from '@icon-park/react';
import cn from 'classnames';

import { MEDAL_TIERS, MEDAL_TYPES } from '@eversity/domain/constants';

import {
  ICON_COLOR_MAPPING,
  ICON_SIZE_MAPPING,
  MEDAL_SIZE_MAPPING,
  MEDAL_SIZES,
  SPARKLE_SIZE_MAPPING,
  TYPE_ICON_MAPPING,
} from './constants';
import * as styles from './Medal.styles';
import MedalIcon from './MedalIcon';

export type MedalProps = {
  tier?: MEDAL_TIERS;
  type?: MEDAL_TYPES;
  size?: MEDAL_SIZES;
  isDisabled?: boolean;
  isSeen?: boolean;
};

const MedalBase = ({
  tier = MEDAL_TIERS.NEUTRAL,
  type = MEDAL_TYPES.CONNECTION_STREAK,
  size = MEDAL_SIZES.MEDIUM,
  isDisabled = false,
  isSeen = true,
}: MedalProps) => {
  const theme = useTheme();
  const iconsColor = ICON_COLOR_MAPPING(theme)[tier];

  const isDefault = useMemo(() => tier === MEDAL_TIERS.NEUTRAL, [tier]);

  return (
    <div
      css={styles.mainContainer}
      className={cn({ isDisabled, isSeen })}
    >
      <MedalIcon
        size={MEDAL_SIZE_MAPPING[size]}
        circleFill={iconsColor[500]}
        circleStroke={iconsColor[700]}
        innerCircleFill={iconsColor[300]}
        pathStroke1={iconsColor[600]}
        pathStroke2={iconsColor[300]}
        pathStroke3={iconsColor[500]}
        pathStroke4={iconsColor[200]}
      />

      <div css={styles.iconContainer}>
        {cloneElement(TYPE_ICON_MAPPING[type], {
          size: ICON_SIZE_MAPPING[size],
          fill: [iconsColor[500], iconsColor[100]],
        })}
      </div>

      <div
        css={styles.firstSparkle}
        className={cn({ isDefault })}
      >
        <Plus
          strokeWidth={8}
          size={SPARKLE_SIZE_MAPPING[size]}
          fill={[theme.colors.gray[0]]}
        />
      </div>

      <div
        css={styles.secondSparkle}
        className={cn({ isDefault })}
      >
        <Plus
          size={SPARKLE_SIZE_MAPPING[size]}
          strokeWidth={8}
          fill={[theme.colors.gray[0]]}
        />
      </div>
    </div>
  );
};

export const Medal: MemoExoticComponent<typeof MedalBase> & {
  SIZES?: typeof MEDAL_SIZES;
  TYPES?: typeof MEDAL_TYPES;
  TIERS?: typeof MEDAL_TIERS;
} = memo(MedalBase);

Medal.SIZES = MEDAL_SIZES;
Medal.TYPES = MEDAL_TYPES;
Medal.TIERS = MEDAL_TIERS;
