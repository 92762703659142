import { type Types } from 'mongoose';
import { type Response } from 'superagent';
import { type Simplify } from 'type-fest';

export * from './guards/index';
export * from './html/index';

export type NumberEnumValue<T extends { [key: string]: number }> = T[keyof T];

export type Nullable<T> = T | null;

export type ColorValueHex = `#${string}`;

export function reverseRecord<T extends PropertyKey, U extends PropertyKey>(
  input: Record<T, U>,
) {
  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [value, key]),
  ) as Record<U, T>;
}

/**
 * @description Transforms a type to another type.
 */
export type TransformTypeToAnother<T, U, V> = T extends U ? V : T;

/**
 * @description Transforms an object id to a string.
 */
export type TransformObjectIdToString<T> = TransformTypeToAnother<
  T,
  Types.ObjectId,
  string
>;

/**
 * @description Transforms a date to a string.
 */
export type TransformDateToString<T> = TransformTypeToAnother<T, Date, string>;

/**
 * @description Transforms a mongoose document array to a normal array.
 */
export type TransformDocumentArrayToArray<T> =
  T extends Types.DocumentArray<infer U> ? TransformObjectIdToString<U>[] : T;

/**
 * @description Transforms a mongoose document array to a normal array.
 */
export type TransformMongooseArrayToArray<T> =
  T extends Types.Array<infer U> ? TransformObjectIdToString<U>[] : T;

/**
 * @description Renames a key of an object.
 */
export type RenameByT<T, U> = {
  [K in keyof U as K extends keyof T
    ? T[K] extends string
      ? T[K]
      : never
    : K]: K extends keyof U ? U[K] : never;
};

/**
 * @description Transforms the mongoose types to the types that are expected to be returned from the API.
 */
export type TransformMongooseTypes<T> = Simplify<
  RenameByT<
    { _id: 'id' },
    {
      [K in keyof T]: TransformDocumentArrayToArray<
        TransformMongooseArrayToArray<
          TransformObjectIdToString<TransformDateToString<T[K]>>
        >
      >;
    }
  >
>;

/**
 * @description Transforms deep the mongoose types to the types that are expected to be returned from the API.
 */
export type TransformDeep<T extends object> = Simplify<{
  [K in keyof T]: TransformMongooseTypes<T[K]>;
}>;

/**
 * @interface TypedResponse
 * @extends Response
 * @description A typed extension of the SuperAgent `Response` interface, allowing for type-safe handling of the response body.
 *
 * @generic T - The expected type of the response body.
 *
 * @property body - The response body, typed according to the specified generic type `T`.
 */
export interface TypedResponse<T> extends Response {
  body: T;
}
